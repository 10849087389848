import React, { memo, useCallback, useState } from "react"
import styled from "@emotion/styled"
import { Paperclip as PaperclipIcon } from "react-feather"
import { MessageModal } from "./MessageModal"
import { useDropzone } from "react-dropzone"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { sendMedia } from "./api"
import { SendingValue } from "../api"
import {tenantStore} from "../../store";

const getFileSize = (bites: number) => {
  const kb = Math.round(bites * 0.0009765625 * 100) / 100
  const mb = () => Math.round(bites * 0.00000095367431640625 * 100) / 100

  return kb > 1024 ? `${mb()}MB` : `${kb}KB`
}

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

const Dropzone = memo(({ onSubmit, closeModal }: Props & { closeModal: () => void }) => {
  const [file, setFile] = useState<File | null>(null)

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader()
      reader.onload = () => {
        setFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const sendFile = (uuid: string, fileExtension?: string) => {
    file &&
      onSubmit({
        message: uuid,
        type: "outgoing-file-message",
        media: {
          fileName: file.name,
          fileSize: getFileSize(file.size),
          fileExtension,
        },
      })
  }

  const handleSend = () => {
    const folder = tenantStore.getState().id

    if (file) {
      const extension = `${file.name.split(".").pop()}`
      sendMedia(file, sendFile, extension, folder)
      closeModal()
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      <FileInputWrap {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <p>Drag 'n' drop some files here, or click to select files</p>
        )}
      </FileInputWrap>
      <TooltipButtonStyled onClick={handleSend} text="Send" />
      {file && <span>{file.name}</span>}
    </>
  )
})

export const FileMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-File-Message"
      icon={<PaperclipIcon />}
      tooltip="File Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <Dropzone onSubmit={onSubmit} closeModal={() => toggleModal(false)} />
    </MessageModal>
  )
}

const FileInputWrap = styled.div`
  height: 200px;
  width: 100%;
  border: 1px dashed #333;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`

const TooltipButtonStyled = styled(TooltipButton)`
  margin-right: 16px;
`
