import React from "react"
import styled from "@emotion/styled"
import SelectComponent from "react-select"

type Props = {
  options: {
    label: string
    value?: string
  }[]
  placeholder?: string
  className?: string
  onInputChange: (value: string) => void
  onChange: (value: any) => void
  selectedOption: { label: string }
  isError?: boolean
}

export const Select = ({
  options,
  placeholder,
  onInputChange,
  onChange,
  className,
  selectedOption,
  isError,
}: Props) => (
  <SelectStyled
    value={selectedOption}
    placeholder={placeholder}
    className={className}
    onInputChange={onInputChange}
    onChange={onChange}
    aria-labelledby="aria-label"
    inputId="aria-example-input"
    name="aria-live-color"
    options={options}
    noOptionsMessage={() => "Start typing"}
    isError={isError}
  />
)

const SelectStyled = styled(SelectComponent)`
  width: 100%;

  & > div {
    ${({ isError }: { isError?: boolean }) => isError && "border-color: #fd397a"}
  }
`
