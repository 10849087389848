import React from "react"

export const archivedChats = [
  {
    name: "Mercedes Pllu",
    title: "Dental Hygienist",
    avatar: (
      <figure className="avatar">
        <span className="avatar-title bg-danger rounded-circle">M</span>
      </figure>
    ),
  },
  {
    name: "Rochelle Golley",
    title: "Marketing Assistant",
    avatar: (
      <figure className="avatar">
        <span className="avatar-title bg-secondary rounded-circle">R</span>
      </figure>
    ),
  },
]
