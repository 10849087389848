import React, { useState } from "react"
import { Tooltip, Button } from "reactstrap"

type Props = {
  onClick: (e: any) => void
  className?: string
  id?: string
  placement?: any
  icon?: JSX.Element
  tooltip?: string
  text?: string
  color?: string
}

export const TooltipButton = ({
  onClick,
  id,
  placement = "bottom",
  icon,
  tooltip,
  text,
  color,
  className,
}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const tooltipToggle = () => setTooltipOpen(!tooltipOpen)

  return (
    <>
      <Button color={color} onClick={onClick} id={id} className={className}>
        {text && text}
        {icon && icon}
      </Button>
      {tooltip && (
        <Tooltip
          placement={placement}
          isOpen={tooltipOpen}
          target={id || ""}
          toggle={tooltipToggle}
        >
          {tooltip}
        </Tooltip>
      )}
    </>
  )
}
