import React, { useCallback, useEffect } from "react"
import { Chat, updateSelectedChat } from "App/Chat/store"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { getMessages, readMessages } from "App/Chat/api"

type Props = {
  chat: Chat
} & RouteComponentProps<{ id?: string }>

export const ChatPreview = withRouter(({ chat, match, history }: Props) => {
  const isCurrentChat = chat.id === Number(match.params.id)

  const chatDate = (chat: Chat) => {
    var date = new Date(chat.date)
    return date.toISOString().substring(0, 10)
  }

  useEffect(() => {
    isCurrentChat &&
      getMessages(null, Number(match.params.id)).then(({ data }) => {
        updateSelectedChat({ chat, messages: data.data, cursor: data.meta.cursor })
      })
  }, [match.params.id])

  const chatSelectHandle = useCallback(() => {
      (async () => {
          await readMessages(chat.id)
      })()
    history.push(`/${chat.id}`)
  }, [chat.id])

  return (
    <li
      className={"list-group-item " + (isCurrentChat ? "open-chat" : "")}
      onClick={chatSelectHandle}
    >
      <figure className="avatar">
        <img
          src={`https://eu.ui-avatars.com/api/?name=${chat.name}&background=007389&color=fff`}
          className="rounded-circle"
          alt="avatar"
        />
      </figure>
      <div className="users-list-body">
        <div>
          <h5 className={chat.unread_messages ? "text-primary" : ""}>{chat.name}</h5>
          <p className={chat.unread_messages ? "padding-right" : ""}>{chat.text}</p>
        </div>
        <div className="users-list-action">
          {chat.unread_messages ? (
            <div className="new-message-count">{chat.unread_messages}</div>
          ) : (
            ""
          )}
          <small className={chat.unread_messages ? "text-primary" : "text-muted"}>
            {chatDate(chat)}
          </small>
        </div>
      </div>
    </li>
  )
})
