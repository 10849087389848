export const tourSteps = [
  {
    selector: "#Tooltip-New-Chat",
    content: "You can create a new chat here.",
  },
  {
    selector: "#Tooltip-Add-Group",
    content: "You can start a new group to chat with all your friends.",
  },
  {
    selector: "#Tooltip-2",
    content: "Layout and messages you've added to your favorites appear here.",
  },
  {
    selector: "#Tooltip-3",
    content: "Layout and messages you've archived appear here.",
  },
  {
    selector: ".layout .content .sidebar-group .sidebar .list-group-item:first-child",
    content: "Select a chat to send a message.",
  },
  {
    selector: "#user-menu",
    content: "Here you can manage your personal information and settings.",
  },
]
