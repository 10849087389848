import React from "react"
import {MediaMessage} from "./MediaMessage"
import { MessageHover } from "./MessageHover"
import { Message } from "App/Chat/store"
import linkifyHtml from "linkifyjs/html"
import parse from "html-react-parser"

export function MessageComponent({
  message,
  isOutgoingMessage,
}: {
  message: Message
  isOutgoingMessage: boolean
}) {
    if (typeof message.media === "object") {
        return (<MediaMessage {...message.media} id={message.id} date={message.date} text={message.text} isOutgoingMessage={isOutgoingMessage} />)
    }

    return (
        <MessageHover date={message.date} isOutgoingMessage={isOutgoingMessage}>
            {parse(
                linkifyHtml(message.text, {
                    defaultProtocol: "https",
                    target: "_blank",
                })
            )}
        </MessageHover>
    )
}
