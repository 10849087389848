import React, { FormEvent, useEffect, useState } from "react"
import { apiClient } from "App/components/services/api"
import { useHistory, useLocation } from "react-router-dom"
import * as FeatherIcon from "react-feather"
import { DARK } from "App/components/Navigation"
import {setUser, rootStore, setCurrentTenant, Store} from "App/store"
import {useStore} from "effector-react";
import {ReactComponent as Logo} from "../../../assets/img/logo.svg"
import {AxiosError, AxiosResponse} from "axios";

type SigninError = {
    errors: Record<string, Partial<string[]>>
    message: string
}


export const SignIn = () => {
  const history = useHistory()

  const location = useLocation();

  const user = useStore(rootStore)

  useEffect(() => {
    const bodyClassList = document.body.classList
    bodyClassList.remove(DARK)
    bodyClassList.add("form-membership")

      return () => {
          const bodyClassList = document.body.classList
          bodyClassList.remove("form-membership", DARK)
      }
  }, [user.id])

  const [sentEmail, setEmail] = React.useState("")
  const [errors, setErrors] = React.useState<Record<string, Partial<string[]>> | undefined>(undefined);
  const [password, setPassword] = React.useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
      if (loading) {
        setErrors(undefined);
      }

  }, [loading])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)

    try {

        await apiClient.get("/sanctum/csrf-cookie")
        const response: AxiosResponse<Store> = await apiClient.post("/api/login", {
          email: sentEmail,
          password: password,
        })

        setUser(response.data)
        document.body.classList.remove("form-membership")
        setLoading(false)

        if (response.data.tenants.length === 1) {
            setCurrentTenant(response.data.tenants[0]);
            history.push("/", location.state);
        } else {
            history.push("/select-account", location.state)
        }


        } catch (e: any) {
            const axiosError = e as AxiosError<SigninError>;

            setLoading(false)

            if (axiosError.isAxiosError) {
                if(axiosError.response && axiosError.response.data) {
                    setErrors(axiosError.response.data.errors);
                }
            }
        }
    }

  return (
    <>
      <div className="mxney-logo">
          <Logo width="80px" />
      </div>
      <div className="form-wrapper">
        <h6>Use your St8te login</h6>
        <h5>Sign in</h5>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              onChange={(e) => {setEmail(e.target.value); if(errors) {setErrors(undefined)} }}
              className="form-control"
              required
            />
              {errors && errors.email && (<span>{errors.email[0]}</span>)}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              placeholder="Password"
              onChange={(e) => {setPassword(e.target.value); if(errors) {setErrors(undefined)}}}
              className="form-control"
              required
            />
          </div>
          <button className="btn btn-dark-black btn-block">
            {loading ? <FeatherIcon.Loader className="btn-loading-icon" /> : ""}
            &nbsp; LOGIN
          </button>
        </form>
      </div>
    </>
  )
}
