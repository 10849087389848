import React, { FormEvent, useCallback, useState } from "react"
import * as FeatherIcon from "react-feather"
import { chatStore, setOutgoingMessage } from "./store"
import { VideoMessageModal, AudioMessageModal, FileMessageModal } from "./MediaMessageModals"
import { TooltipButton } from "App/components/ui/TooltipButton"
import styled from "@emotion/styled"
import { readMessages, SendingValue, sendMessage } from "./api"
import { useStore } from "effector-react"
import { rootStore, tenantStore } from "../store"

export const ChatFooter = () => {
  const [inputMsg, setInputMsg] = useState("")
  const user = useStore(rootStore)
  const { supports_sms } = useStore(tenantStore)

  const { selectedChat } = useStore(chatStore)

  const [isPhone, setPhone] = useState(false)

  const onSubmit = useCallback(
    (newValue: SendingValue) => {
      sendMessage(selectedChat.id, newValue).then(({ data }) => {
        setOutgoingMessage(data)
      })
      setInputMsg("")
      setPhone(false)
    },
    [selectedChat.id]
  )

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit({
      message: inputMsg,
      type: isPhone ? "outgoing-sms-message" : "outgoing-message",
      media: "text-message",
    })
  }

  const handleChange = (e: any) => {
    setInputMsg(e.target.value)
  }

  const onKeyDownHandler = (e: any) => {
    const shiftEnter = e.key === "Enter" && e.shiftKey
    if (e.key === "Enter" && !shiftEnter) {
      handleSubmit(e)
    }
  }

  return (
    <ChatFooterWrap>
      <Form>
        <Textarea
          onFocus={() => readMessages(selectedChat.id)}
          placeholder="Write a message."
          value={inputMsg}
          onChange={handleChange}
          onKeyDown={onKeyDownHandler}
        />
        <FormButtons>
          <FileMessageModal onSubmit={onSubmit} />
          <VideoMessageModal onSubmit={onSubmit} />
          <AudioMessageModal onSubmit={onSubmit} />
            {user.mxney_staff === true && supports_sms === true && (
              <SendSmsButton
                onClick={() => setPhone(!isPhone)}
                color={isPhone ? "primary" : "link"}
                icon={<FeatherIcon.Smartphone/>}
              />
            )
            }
          <SendMessageButton onClick={handleSubmit} color="primary" icon={<FeatherIcon.Send />} />
        </FormButtons>
      </Form>
    </ChatFooterWrap>
  )
}

const ChatFooterWrap = styled.div`
  border-top: 1px solid #e6e6e6;
  padding: 17px 30px;

  @media (max-width: 1200px) {
    padding-bottom: 17px;
  }
`

const Form = styled.div`
  display: flex;
  align-items: center;
`

const Textarea = styled.textarea`
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`

const FormButtons = styled.div`
  display: flex;
`

const SendMessageButton = styled(TooltipButton)`
  margin-left: 8px;
`

const SendSmsButton = styled(TooltipButton)`
  margin-left: 8px;
`
