import React from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import "assets/scss/Index.scss"
import { App } from "./App"
import * as serviceWorker from "./serviceWorker"
import { attachLogger } from "effector-logger/attach"
import { root } from "effector-root"
import { Provider } from "react-redux"
import { store } from "./Store"

attachLogger(root, {
  console: "enabled",
    inspector: "enabled",
    reduxDevtools: "enabled"
})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
