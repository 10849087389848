import React, { useCallback, useEffect, useRef, useState } from "react"
import Webcam from "react-webcam"
import styled from "@emotion/styled"
import { TooltipButton } from "App/components/ui/TooltipButton"
import { Video as VideoIcon } from "react-feather"
import { sendMedia } from "./api"
import { ErrorModalBody } from "./ErrorModalBody"
import { checkMediaAvailable, mediaStore } from "./store"
import { useStore } from "effector-react"
import { MessageModal } from "./MessageModal"
import { SendingValue } from "App/Chat/api"
import {tenantStore} from "../../store";

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

type CloseModal = { closeModal: () => void }

type StartStopProps = {
  isCapturing: boolean
  start: () => void
  stop: () => void
}

const StartStopButtons = ({ isCapturing, start, stop }: StartStopProps) =>
  isCapturing ? (
    <TooltipButton onClick={stop} text="Stop Recording" />
  ) : (
    <TooltipButton onClick={start} text="Start Recording" />
  )

const WebcamStreamCapture = ({ closeModal, onSubmit }: Props & CloseModal) => {
  const webcamRef = useRef<Webcam>(null)
  const mediaRecorderRef = useRef<any>(null)
  const [isCapturing, setCapturing] = useState(false)
  const [recordedChunks, setRecordedChunks] = useState([])

  const doneRecording = (uuid: string) => {
    onSubmit({
      message: uuid,
      type: "outgoing-video-message",
      media: "video-message",
    })
  }

  const handleStartCaptureClick = useCallback(() => {
    const stream = webcamRef?.current?.stream
    setCapturing(true)

    if (stream) {
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "video/webm",
      })
    }

    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable)
    mediaRecorderRef.current.start()
  }, [webcamRef, setCapturing, mediaRecorderRef])

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data))
      }
    },
    [setRecordedChunks]
  )

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop()
    setCapturing(false)
  }, [mediaRecorderRef, webcamRef, setCapturing])

  const handleSend = useCallback(() => {
    const folder = tenantStore.getState().id;

    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      })
      sendMedia(blob, doneRecording, "webm", folder)
      closeModal()
    }
  }, [recordedChunks])

  return (
    <>
      <WebcamWrap>
        <Webcam ref={webcamRef} mirrored={true} />
      </WebcamWrap>
      {recordedChunks.length ? (
        <TooltipButton onClick={handleSend} text="Send Video" />
      ) : (
        <StartStopButtons
          start={handleStartCaptureClick}
          stop={handleStopCaptureClick}
          isCapturing={isCapturing}
        />
      )}
    </>
  )
}

const VideoModalBody = ({ onSubmit, closeModal }: Props & CloseModal) => {
  const { audio, video } = useStore(mediaStore)

  useEffect(() => {
    !audio && !video && checkMediaAvailable({ audio: true, video: true })
  }, [])

  if (audio === null || video === null) {
    return null
  }

  return audio && video ? (
    <WebcamStreamCapture onSubmit={onSubmit} closeModal={closeModal} />
  ) : (
    <ErrorModalBody />
  )
}

export const VideoMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-Video-Message"
      icon={<VideoIcon />}
      tooltip="Video Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <VideoModalBody onSubmit={onSubmit} closeModal={() => toggleModal(false)} />
    </MessageModal>
  )
}

const WebcamWrap = styled.div`
  video {
    width: 100%;
  }
`
