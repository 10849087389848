import { chatInit } from "App/Chat/store"
import { createEffect, createEvent, createStore } from "effector-root"
import { setGroup } from "./api"

type User = { id: number; name: string; label: string }

type Group = {
  groupName: string
  usersInGroup: User[]
  firstMessage: string
}

type Store = Group & {
  validation: { groupName: boolean; usersInGroup: boolean; firstMessage: boolean }
}

const defaultStore: Store = {
  groupName: "",
  usersInGroup: [{ id: 0, name: "", label: "" }],
  firstMessage: "",
  validation: {
    groupName: true,
    usersInGroup: true,
    firstMessage: true,
  },
}

export const setGroupName = createEvent<string>()
export const setFirstMessage = createEvent<string>()
export const setUserName = createEvent<{ index: number; name: string }>()
export const setUserId = createEvent<{ index: number; id: number; label: string }>()
export const addUser = createEvent()
export const removeUser = createEvent<number>()
export const setValidation =
  createEvent<{ groupName?: boolean; usersInGroup?: boolean; firstMessage?: boolean }>()
export const resetGroup = createEvent()

export const createGroup = createEffect({
  handler: ({ groupName, firstMessage, usersInGroup }: Group) => {
    const display_names = usersInGroup.reduce(
      (accum, { id, name, label }) => (id ? { ...accum, [id]: name || label } : accum),
      {}
    )
    const group = {
      name: groupName,
      display_names,
      first_message: firstMessage,
    }
    return setGroup(group)
  },
})
createGroup.done.watch(() => chatInit())

export const createGroupModalStore = createStore(defaultStore)
  .on(setUserName, (store, { index, name }) => ({
    ...store,
    usersInGroup: store.usersInGroup.map((user, i) =>
      i === index
        ? {
            ...user,
            name,
          }
        : user
    ),
  }))
  .on(setUserId, (store, { id, index, label }) => ({
    ...store,
    usersInGroup: store.usersInGroup.map((user, i) =>
      i === index
        ? {
            ...user,
            id,
            label,
          }
        : user
    ),
  }))
  .on(addUser, (store) => ({
    ...store,
    usersInGroup: [...store.usersInGroup, { id: 0, name: "", label: "" }],
  }))
  .on(removeUser, (store, index) => {
    const usersInGroup = [...store.usersInGroup]
    usersInGroup.splice(index, 1)

    return {
      ...store,
      usersInGroup,
    }
  })
  .on(setGroupName, (store, groupName) => ({
    ...store,
    groupName,
  }))
  .on(setFirstMessage, (store, firstMessage) => ({
    ...store,
    firstMessage,
  }))
  .on(setValidation, (store, valid) => ({
    ...store,
    validation: {
      ...store.validation,
      ...valid,
    },
  }))
  .reset(resetGroup)
