import React from "react"

export const favoriteChats = [
  {
    name: "Townsend Seary",
    text: <p>What's up, how are you?</p>,
    date: "03:41 PM",
    unread_messages: 3,
  },
  {
    name: "Forest Kroch",
    text: (
      <p>
        <i className="fa fa-camera mr-1"></i> Photo
      </p>
    ),
    unread_messages: 1,
    date: "03:41 PM",
  },
  {
    name: "Byrom Guittet",
    text: (
      <p>
        I sent you all the files. Good luck with{" "}
        <span role="img" aria-label="xxx">
          😃
        </span>
      </p>
    ),
    selected: true,
    date: "03:41 PM",
  },
  {
    name: "Margaretta Worvell",
    text: <p>I need you today. Can you come with me?</p>,
    date: "03:41 PM",
  },
]
