import React, { useCallback, useState } from "react"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  InputGroup,
} from "reactstrap"
import * as FeatherIcon from "react-feather"
import { GroupMember } from "./GroupMember"
import { TooltipButton } from "App/components/ui/TooltipButton"
import {
  createGroup,
  createGroupModalStore,
  resetGroup,
  setFirstMessage,
  setGroupName,
  setValidation,
} from "./store"
import { useStore } from "effector-react"

export const CreateGroupModal = () => {
  const { usersInGroup, groupName, firstMessage, validation } = useStore(createGroupModalStore)
  const [modal, setModal] = useState(false)

  const closeModal = useCallback(() => {
    setModal(false)
    resetGroup()
  }, [])

  return (
    <div>
      <TooltipButton
        onClick={() => setModal(true)}
        id="Tooltip-Add-Group"
        tooltip="Add group"
        icon={<FeatherIcon.Users />}
        className="btn btn-outline-light"
      />
      <Modal className="modal-dialog-zoom" isOpen={modal} toggle={closeModal} centered>
        <ModalHeader toggle={closeModal}>
          <FeatherIcon.UserPlus className="mr-2" /> Add Group
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label>Group name</Label>
              <InputGroup>
                <Input
                  className={validation.groupName ? "" : "input-error"}
                  type="text"
                  value={groupName}
                  onChange={(e) => setGroupName(e.target.value)}
                />
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label for="group_name">The group members</Label>
              {usersInGroup.map(({ id, name, label }, i, array) => (
                <GroupMember
                  key={i}
                  isFirst={i === array.length - 1}
                  name={name}
                  id={id}
                  index={i}
                  label={label}
                  isError={!validation.usersInGroup}
                />
              ))}
            </FormGroup>

            <FormGroup>
              <Label for="description">Invitation message</Label>
              <Input
                type="textarea"
                value={firstMessage}
                onChange={(e) => setFirstMessage(e.target.value)}
                className={validation.firstMessage ? "" : "input-error"}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              const isUserInGroup = usersInGroup.some(({ id }) => id)
              setValidation({
                groupName: !!groupName,
                firstMessage: !!firstMessage,
                usersInGroup: isUserInGroup,
              })
              if (groupName && firstMessage && isUserInGroup) {
                createGroup({ usersInGroup, groupName, firstMessage })
                closeModal()
              }
            }}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
