import React, { useState } from "react"
import styled from "@emotion/styled"
import { MessageHover } from "App/Chat/MessagesView/MessageHover"
import { downloadFIle, errorHandler } from "./handlers"

type ImageProps = {
  date: Date
  url: string
  isOutgoingMessage: boolean
  download: string
  fileName?: string
  fileSize?: string
}

export const ImageView = ({
  date,
  url,
  isOutgoingMessage,
  download,
  fileName,
  fileSize,
}: ImageProps) => {
  const [key, updateKey] = useState(0)
  const [isFancy, toggleFancy] = useState(false)

  return (
    <>
      <MessageHover date={date} isOutgoingMessage={isOutgoingMessage} className="message-image">
        <div className="image-wrap mb-3">
          <img
            key={key}
            src={url}
            onError={() => errorHandler(key, updateKey)}
            onClick={() => toggleFancy(true)}
          />
        </div>
        <div>
          <ul className="list-inline">
            <li className="list-inline-item mb-0">
              <a href="#" onClick={() => downloadFIle(download, fileName)}>
                Download
              </a>
              <i className="text-muted small">({fileSize})</i>
            </li>
          </ul>
        </div>
      </MessageHover>
      {isFancy && (
        <Fancy onClick={() => toggleFancy(false)}>
          <FancyImg key={key} src={url} />
        </Fancy>
      )}
    </>
  )
}

const Fancy = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 9999;
`

const FancyImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
`
