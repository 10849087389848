import { apiClient } from "App/components/services/api"
import { echo } from "App/components/services/webscokets"
import {updateUnreadMessage} from "./store"

type MediaObject = {
  fileName: string
  fileSize: string
  fileExtension?: string
}

export type SendingValue = {
  message: string
  type: string
  media: string | MediaObject
}

export const sendMessage = (id: number, { message, type, media }: SendingValue) => {
  const fileData =
    typeof media === "object"
      ? { fileName: media.fileName, fileSize: media.fileSize, fileExtension: media.fileExtension }
      : undefined

  return apiClient.post(
    `/api/groups/${id}/messages`,
    {
      message,
      type,
      ...fileData,
    },
    {
      headers: {
        "X-Socket-Id": echo.socketId(),
      },
    }
  )
}

export const getMessages = (cursor: null|string, id: number) =>
    apiClient.get(`/api/groups/${id}/messages`, {
        params: cursor ? {
            cursor,
        } : {},
    })

export const sendRetrySMSRequest = (id: number) =>
    apiClient.post(`/api/messages/${id}/smses/retry`)

export const readMessages = (id: number) =>
  apiClient.post(`/api/groups/${id}/read`).then(() => updateUnreadMessage({ groupId: id }))
