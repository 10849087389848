import React, { useEffect, useState } from "react"
import { Mic as MicIcon } from "react-feather"
import { sendMedia } from "./api"
import { ErrorModalBody } from "./ErrorModalBody"
import { useStore } from "effector-react"
import { checkMediaAvailable, mediaStore } from "./store"
import { MessageModal } from "./MessageModal"
import { SendingValue } from "App/Chat/api"
import {tenantStore} from "../../store";

type Props = {
  onSubmit: (newValue: SendingValue) => void
}

const AudioCapture = ({
  onSubmit,
  showModal,
}: Props & { showModal: (isModal: boolean) => void }) => {
  const [recording, setRecording] = useState<MediaRecorder | null>(null)

  const doneRecording = (uuid: string) => {
    onSubmit({
      message: uuid,
      type: "outgoing-audio-message",
      media: "audio-message",
    })
  }

  const startRecordingModal = async () => {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    const audioChunks: Blob[] = []

    const rec = new MediaRecorder(stream)
    rec.ondataavailable = ({ data }: { data: Blob }) => {
      const folder = tenantStore.getState().id

      audioChunks.push(data)
      if (rec.state == "inactive") {
        if (stream.getAudioTracks().length === 0) {
            return;
        }

        stream.getAudioTracks().forEach((track) => {
          track.stop()
        })

        const blob = new Blob(audioChunks, { type: "audio/mp3" })
        sendMedia(blob, doneRecording, "mp3", folder)
      }
    }

    rec.start()
    setRecording(rec)
  }

  const endRecording = (close: boolean) => {
    recording?.stop()
      if (close) {
        showModal(false)
      }
  }

  useEffect(() => {
      (async () => {
        await startRecordingModal()
      })()
  }, [])

  return (
    <div className="call">
      <div>
        <figure className="avatar avatar-xl mb-4">
          <MicIcon size={100} />
        </figure>
        <h4>
          <span className="text-success">Recoding...</span>
          <br />
          <button className="btn btn-danger mt-2" onClick={() => endRecording(true)}>
            Done
          </button>
          <audio id="recordedAudio" />
        </h4>
        <div className="action-button" />
      </div>
    </div>
  )
}

const AudioModalBody = ({ onSubmit, showModal }: Props & { showModal: any }) => {
  const { audio } = useStore(mediaStore)

  useEffect(() => {
    !audio && checkMediaAvailable({ audio: true, video: false })
  }, [])

  if (audio === null) {
    return null
  }

  return audio ? <AudioCapture onSubmit={onSubmit} showModal={showModal} /> : <ErrorModalBody />
}

export const AudioMessageModal = ({ onSubmit }: Props) => {
  const [isModal, toggleModal] = useState(false)

  return (
    <MessageModal
      id="Tooltip-Voice-Message"
      icon={<MicIcon />}
      tooltip="Audio Message"
      isModal={isModal}
      toggleModal={toggleModal}
    >
      <AudioModalBody showModal={toggleModal} onSubmit={onSubmit} />
    </MessageModal>
  )
}
