import React from "react"
import UnselectedChat from "assets/img/unselected-chat.svg"

export const ChatEmpty = () => (
  <div className="chat-body no-message">
    <div className="no-message-container">
      <div className="mb-5">
        <img src={UnselectedChat} width={200} className="img-fluid" alt="unselected" />
      </div>
      <p className="lead">Select a chat to read messages</p>
    </div>
  </div>
)
