import React, {FormEvent, useEffect, useState} from "react"
import {useHistory, useLocation} from "react-router-dom";
import {ReactComponent as Logo} from "../../../assets/img/logo.svg";
import * as FeatherIcon from "react-feather";
import {DARK} from "../Navigation";
import {useStore} from "effector-react";
import {rootStore} from "../../store";
import {apiClient} from "../services/api";
import {AxiosError} from "axios";

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const ChangePassword = () => {
    const history = useHistory()
    const query = useQuery()
    const user = useStore(rootStore)

    const [currentPassword, setCurrentPassword] = React.useState("")
    const [passwordConfirmation, setPasswordConfirmation] = React.useState("")
    const [errors, setErrors] = React.useState<Record<string, Partial<string[]>> | undefined>(undefined);
    const [password, setPassword] = React.useState("")
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        if (user.id !== null) {
            const bodyClassList = document.body.classList
            bodyClassList.remove(DARK)
            bodyClassList.add("form-membership")
        }

        return () => {
            const bodyClassList = document.body.classList
            bodyClassList.remove("form-membership", DARK)
        }
    }, [user.id])


    useEffect(() => {
        if (loading) {
            setErrors(undefined);
        }

    }, [loading])

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setLoading(true)

        try {
            await apiClient.post("/api/user/password", {
                password: password,
                password_confirmation: passwordConfirmation
            })

            setLoading(false)

            history.push(`/${Number(query.get("redirect"))}`)
        } catch (e: any) {
            const axiosError = e as AxiosError;
            setLoading(false)

            if (axiosError.isAxiosError) {
                if(axiosError.response && axiosError.response.data) {
                    setErrors(axiosError.response.data.errors);
                }
            }
        }
    }


    return (
        <>
            <div className="mxney-logo">
                <Logo width="80px" />
            </div>
            <div className="form-wrapper">
                <h6>Choose your password to proceed</h6>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            placeholder="Password"
                            onChange={(e) => setPassword(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password_confirmation">Password</label>
                        <input
                            type="password"
                            name="password_confirmation"
                            placeholder="Gentag kodeord"
                            onChange={(e) => setPasswordConfirmation(e.target.value)}
                            className="form-control"
                            required
                        />
                    </div>
                    <button className="btn btn-dark-black btn-block">
                        {loading ? <FeatherIcon.Loader className="btn-loading-icon" /> : ""}
                        &nbsp; LOGIN
                    </button>
                </form>
            </div>
        </>
    )
}

