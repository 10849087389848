import { createEvent, createStore } from "effector-root"

export type Tenant = {
    id: string | null,
    cvr_number?: string,
    name?: string
    supports_sms?: boolean
}

export interface Tenants extends Array<Tenant> {

}

export type Store = {
  name: string
  email: string
  mxney_staff?: boolean
  id?: null | number
  mxney_refresh_token?: null | string
  mxney_access_token?: null | string
  created_at?: string
  updated_at?: string
  tenants: Tenants
}

const defaultStore: Store = {
  name: "",
  email: "",
  mxney_staff: false,
  id: null,
  mxney_refresh_token: null,
  mxney_access_token: null,
  created_at: "",
  updated_at: "",
  tenants: []
}

const currentTenantDefault: Tenant = {
    id: null
}
export const setCurrentTenant = createEvent<Tenant>()
export const resetCurrentTenant = createEvent()
export const tenantStore = createStore(currentTenantDefault)
    .on(setCurrentTenant, (store, tenant) => ({
        ...store,
        ...tenant
    })).reset(resetCurrentTenant)

export const setUser = createEvent<Store>()
export const resetUser = createEvent()

export const rootStore = createStore(defaultStore)
  .on(setUser, (store, user) => ({
      ...store,
      ...user
  }))
  .reset(resetUser)
