import React, { memo, useMemo } from "react"
import { MessageComponent } from "./MessageComponent"
import { Message } from "App/Chat/store"

type Props = {
  message: Message
  sameUser: number
}

export const MessagesView = memo(({ message, sameUser }: Props) => {
  const messageDate = (date: Date) => new Date(date).toLocaleDateString()
  const messageTime = (date: Date) => new Date(date).toLocaleTimeString()

  const isOutgoingMessage = useMemo(() => message.type.split("-")[0] === "outgoing", [message.type])

  if (message.type === "divider") {
    return (
      <div className="message-item messages-divider sticky-top" data-label={message.text}></div>
    )
  }

  return (
    <div className={"message-item " + message.type + " sameUser-" + sameUser}>
      {!sameUser && (
        <div className="message-avatar">
          <figure className="avatar">
            <img
              src={`https://eu.ui-avatars.com/api/?name=${message.name}&background=007389&color=fff`}
              className="rounded-circle"
              alt="avatar"
            />
          </figure>
          <div>
            <h5>{message.name}</h5>
            <div className="time">
              {messageDate(message.date)} <span>{messageTime(message.date)}</span>
            </div>
          </div>
        </div>
      )}
      <MessageComponent message={message} isOutgoingMessage={isOutgoingMessage} />
    </div>
  )
})
