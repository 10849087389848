import React, { useState } from "react"
import { useDispatch } from "react-redux"
import * as FeatherIcon from "react-feather"
import { profileAction } from "Store/Actions/profileAction"
import { mobileProfileAction } from "Store/Actions/mobileProfileAction"
import { Chat } from "./store"
import { useHistory } from "react-router-dom"

type Props = {
  selectedChat: Chat
}
export const ChatHeader = ({ selectedChat }: Props) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => setDropdownOpen((prevState) => !prevState)

  const selectedChatClose = () => history.push("/")

  const profileActions = () => {
    dispatch(profileAction(true))
    dispatch(mobileProfileAction(true))
  }

  return (
    <div className="chat-header">
      <div className="chat-header-user">
        <figure className="avatar">
          <img
            src={`https://eu.ui-avatars.com/api/?name=${selectedChat.name}&background=007389&color=fff`}
            className="rounded-circle"
            alt="avatar"
          />
        </figure>
        <div>
          <h5>{selectedChat.name}</h5>
          <small className="text-muted">{/*<i>5 minutes ago</i>*/}</small>
        </div>
      </div>
      <div className="chat-header-action">
        <ul className="list-inline">
          <li className="list-inline-item d-xl-none d-inline">
            <button
              onClick={selectedChatClose}
              className="btn btn-outline-light text-danger mobile-navigation-button"
            >
              <FeatherIcon.X />
            </button>
          </li>
          {/*
						<li className="list-inline-item">
								<VoiceCallModal/>
						</li>
						<li className="list-inline-item">
								<VideoCallModal/>
						</li>
					*/}
          <li className="list-inline-item" data-toggle="tooltip" title="Video call">
            {/*
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                tag="span"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
              >
                <button className="btn btn-outline-light">
                  <FeatherIcon.MoreHorizontal />
                </button>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem onClick={profileActions}>Profile</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            */}
          </li>
        </ul>
      </div>
    </div>
  )
}
