import { apiClient } from "App/components/services/api"

export interface Users extends Array<User> {}

type User = {
    email: string
    id: number
    mxney_staff: boolean
    name: string
}


export const getUsers = async (value: string): Promise<Users> => {
  const { data } = await apiClient.get("/api/users", {
    params: {
      q: value,
    },
  })

  return data
}

type Group = {
  name: string
  display_names: { [id: number]: string }
  first_message: string
}

export const setGroup = (group: Group) => apiClient.post("/api/groups", group)
