import React, { useEffect, useState } from "react"
import { ChatHeader } from "./ChatHeader"
import { ChatFooter } from "./ChatFooter"
import PerfectScrollbar from "react-perfect-scrollbar"
import { chatStore, getNextPage, Message, setOutgoingMessage } from "./store"
import { useStore } from "effector-react"
import { withRouter, RouteComponentProps } from "react-router-dom"
import { MessagesView } from "./MessagesView"
import { ChatEmpty } from "./ChatEmpty"

export const Chat = withRouter(({ match }: RouteComponentProps<{ id?: string }>) => {
  const { selectedChat } = useStore(chatStore)
  const [scrollEl, setScrollEl] = useState<HTMLElement | null>(null)
  const [scrollOffset, setScrollOffset] = useState(0)

  useEffect(() => {
    if (scrollEl) {
      scrollEl.scrollTo({ top: scrollEl.scrollHeight - scrollOffset })
      setScrollOffset(scrollEl.scrollHeight)
    }
  }, [selectedChat.cursor.current])

  useEffect(() => {
    if (scrollEl) {
      setOutgoingMessage.watch(() => {
        scrollEl.scrollTo({ top: scrollEl.scrollHeight })
      })

      scrollEl.scrollTo({ top: scrollEl.scrollHeight })
      setScrollOffset(scrollEl.scrollHeight)
    }
  }, [scrollEl, selectedChat.id, selectedChat.date])

  let currentMessageUser = ""

  return (
    <div className={`chat ${isNaN(Number(match.params.id)) ? "" : "open"}`}>
      {selectedChat.name ? (
        <>
          <ChatHeader selectedChat={selectedChat} />
          <PerfectScrollbar
            onScrollY={(e) => {
              if (e.scrollTop === 0) {
                const isCurrentChat = Number(match.params.id) === selectedChat.id
                isCurrentChat && getNextPage(selectedChat.id)
              }
            }}
            containerRef={setScrollEl}
          >
            <div className="chat-body">
              <div className="messages">
                {selectedChat.messages
                  ? selectedChat.messages.map((message: Message, i: number) => {
                      if (message.name === currentMessageUser) {
                        return <MessagesView message={message} key={i} sameUser={1} />
                      }
                      currentMessageUser = message.name
                      return <MessagesView message={message} key={i} sameUser={0} />
                    })
                  : null}
              </div>
            </div>
          </PerfectScrollbar>
          <ChatFooter />
        </>
      ) : (
        <ChatEmpty />
      )}
    </div>
  )
})
