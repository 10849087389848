import React from "react"
import * as FeatherIcon from "react-feather"
import "react-perfect-scrollbar/dist/css/styles.css"
import PerfectScrollbar from "react-perfect-scrollbar"
import { CreateGroupModal } from "App/CreateGroupModal"
import { Chat, chatStore } from "App/Chat/store"
import { useStore } from "effector-react"
import { ChatPreview } from "./ChatPreview"
import { rootStore } from "App/store"

export const ChatsList = () => {
  const { chatsList } = useStore(chatStore)
  const { mxney_staff } = useStore(rootStore)

  const mobileMenuBtn = () => document.body.classList.toggle("navigation-open")

  return (
    <div className="sidebar active">
      <header>
        <div className="d-flex align-items-center">
          <button
            onClick={mobileMenuBtn}
            className="btn btn-outline-light mobile-navigation-button mr-3 d-xl-none d-inline"
          >
            <FeatherIcon.Menu />
          </button>
          <span className="sidebar-title">Chats</span>
        </div>
        <ul className="list-inline">
          {mxney_staff && (
            <li className="list-inline-item">
              <CreateGroupModal />
            </li>
          )}
          <li className="list-inline-item"></li>
        </ul>
      </header>
      <div className="sidebar-body">
        <PerfectScrollbar>
          <ul className="list-group list-group-flush">
            {chatsList.map((chat: Chat, i: number) => (
              <ChatPreview chat={chat} key={i} />
            ))}
          </ul>
        </PerfectScrollbar>
      </div>
    </div>
  )
}
