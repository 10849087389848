import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import React, {useEffect} from "react";
import {AxiosError, AxiosResponse} from "axios";
import {useErrorHandler, withErrorBoundary} from "react-error-boundary";
import {apiClient} from "../services/api";
import {Store, setUser, setCurrentTenant, Tenants, Tenant} from "../../store";
import {ErrorFallback} from "../Error";


function useQuery(search: string)
{
    return new URLSearchParams(search)
}

export const InstantLogin = withErrorBoundary(withRouter(({location}: RouteComponentProps) => {
    const query = useQuery(location.search);
    const history = useHistory()
    const handleError = useErrorHandler()

    const findTenant = (tenants: Tenants): Tenant | undefined => tenants.find((t) => t.id == query.get("tenant_id"))

    const getUrlToken = (): string => {
        let token = query.get("token");

        if (! token) {
            token = "";
        }

        return token.trim();
    }

    useEffect(() => {

        apiClient.post(`/api/instantlogin?token=${getUrlToken()}`).then((response: AxiosResponse<Store>) => {
            setUser(response.data);
            const tenant = findTenant(response.data.tenants);

            if (tenant) {
                setCurrentTenant(tenant)
            }

            history.push("/");
        }).catch((error: AxiosError) => handleError(error))

    }, []);

    return (
        <>
        </>
    );
}), {
    FallbackComponent: ErrorFallback,
})
