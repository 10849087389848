import axios from "axios"
import {tenantStore, resetCurrentTenant, resetUser} from "../../store"

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

apiClient.interceptors.request.use((config) => {
    const state = tenantStore.getState();

    if (state.id !== null) {
        config.headers["X-customer"] = state.id;
    }

    return config;
})

apiClient.interceptors.response.use((response) => {
    if (response.status >= 400) {
        resetCurrentTenant();
        resetUser();
    }

    return response;
})
