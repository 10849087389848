import React, { MouseEvent, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Tooltip, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
import * as FeatherIcon from "react-feather"
import { sidebarAction } from "Store/Actions/sidebarAction"
import { EditProfileModal } from "App/Modals/EditProfileModal"
import { profileAction } from "Store/Actions/profileAction"
import { SettingsModal } from "App/Modals/SettingsModal"
import { mobileSidebarAction } from "Store/Actions/mobileSidebarAction"
import { mobileProfileAction } from "Store/Actions/mobileProfileAction"
import { RootStore } from "Store"
import { useHistory } from "react-router-dom"
import {rootStore, resetUser} from "App/store"
import { useStore } from "effector-react"
import {apiClient} from "./services/api";

type NavigationItemViewProps = {
  item: {
    badge: string
    icon: JSX.Element
    name: string
  }
  tooltipName: string
}

const NavigationItemView = ({ item, tooltipName }: NavigationItemViewProps) => {
  const dispatch = useDispatch()

  const { selectedSidebar } = useSelector((state: RootStore) => state)
  const [tooltipOpen, setTooltipOpen] = useState(false)

  const toggle = () => setTooltipOpen(!tooltipOpen)

  const linkDispatch = (e: MouseEvent) => {
    e.preventDefault()
    dispatch(sidebarAction(item.name))
    dispatch(mobileSidebarAction(true))
    document.querySelector(".chat")?.classList.remove("open")
    document.body.classList.toggle("navigation-open")
  }

  return (
    <li>
      <a
        onClick={linkDispatch}
        href={item.name}
        className={`sidebar ${selectedSidebar === item.name ? "active" : ""}`}
        id={tooltipName}
      >
        {item.badge && <span className={"badge badge-" + item.badge}>&nbsp;</span>}
        {item.icon}
      </a>
      <Tooltip placement="right" isOpen={tooltipOpen} target={tooltipName} toggle={toggle}>
        {item.name}
      </Tooltip>
    </li>
  )
}

export const DARK = "dark"

export const Navigation = () => {
  const { name, mxney_staff } = useStore(rootStore)
  const history = useHistory()

  const dispatch = useDispatch()

  const [userMenuTooltipOpen, setUserMenuTooltipOpen] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [darkSwitcherTooltipOpen, setDarkSwitcherTooltipOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const userMenuToggle = () => !dropdownOpen && setUserMenuTooltipOpen(!userMenuTooltipOpen)

  const toggle = () =>
    setDropdownOpen((prevState) => {
      setUserMenuTooltipOpen(false)
      return !prevState
    })

  const darkSwitcherTooltipToggle = () => setDarkSwitcherTooltipOpen(!darkSwitcherTooltipOpen)

  useEffect(() => {
    const isDarkMode = localStorage.getItem(DARK)
    isDarkMode && document.body.classList.add(DARK)
  }, [])

  const darkSwitcherToggle = (e: MouseEvent) => {
    e.preventDefault()
    const bodyClassList = document.body.classList
    bodyClassList.toggle(DARK)
    bodyClassList.contains(DARK) ? localStorage.setItem(DARK, "1") : localStorage.removeItem(DARK)
  }

  const editModalToggle = () => setEditModalOpen(!editModalOpen)

  const [settingsModalOpen, setSettingsModalOpen] = useState(false)

  const settingsModalToggle = () => setSettingsModalOpen(!settingsModalOpen)

  const profileActions = () => {
    dispatch(profileAction(true))
    dispatch(mobileProfileAction(true))
  }

  const logOut = async () => {
    await apiClient.delete("/api/logout")
    resetUser()
    history.push("/login")
  }

  const navigationItems = [
    {
      name: "Chats",
      icon: <FeatherIcon.MessageCircle />,
      badge: "warning",
    },
  ]

  return (
    <nav className="navigation">
      <EditProfileModal modal={editModalOpen} toggle={editModalToggle} />
      <SettingsModal modal={settingsModalOpen} toggle={settingsModalToggle} />
      <div className="nav-group">
        <ul>
          {mxney_staff ? (
            <li className="logoNav">
              <a href="https://app.webapp.test">
                <div className="">
                  <FeatherIcon.ArrowLeftCircle />
                  <br />
                  <span>Back to St8te</span>
                </div>
              </a>
            </li>
          ) : null}
          {navigationItems.map((item, i) => (
            <NavigationItemView key={i} item={item} tooltipName={"Tooltip-" + i} />
          ))}
          <li className="scissors">
            <a
              href="#/"
              onClick={darkSwitcherToggle}
              className="dark-light-switcher"
              id="dark-switcher"
            >
              <FeatherIcon.Moon />
            </a>
            <Tooltip
              placement="right"
              isOpen={darkSwitcherTooltipOpen}
              target="dark-switcher"
              toggle={darkSwitcherTooltipToggle}
            >
              Dark mode
            </Tooltip>
          </li>
          <li id="user-menu" className="text-center">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle tag="span" data-toggle="dropdown" aria-expanded={dropdownOpen}>
                <figure className="avatar">
                  <img
                    src={`https://eu.ui-avatars.com/api/?name=${name}&background=007389&color=fff`}
                    className="rounded-circle"
                    alt="avatar"
                  />
                </figure>
              </DropdownToggle>

              <DropdownMenu>
                {/* <DropdownItem onClick={editModalToggle}>Edit profile</DropdownItem>
                <DropdownItem onClick={profileActions}>Profile</DropdownItem>
                <DropdownItem onClick={settingsModalToggle}>Settings</DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem onClick={logOut}>Logout</DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <Tooltip
              placement="right"
              isOpen={userMenuTooltipOpen}
              target="user-menu"
              toggle={userMenuToggle}
            >
              User menu
            </Tooltip>
          </li>
        </ul>
      </div>
    </nav>
  )
}
