import React from "react"
import { MessageHover } from "App/Chat/MessagesView/MessageHover"
import { downloadFIle } from "./handlers"

type FileProps = {
  date: Date
  isOutgoingMessage: boolean
  download: string
  fileName?: string
  fileSize?: string
}

export const FileView = ({ isOutgoingMessage, date, download, fileName, fileSize }: FileProps) => (
  <MessageHover date={date} isOutgoingMessage={isOutgoingMessage} className="message-file">
    <div className="file-icon">
      <i className="fa fa-file-pdf-o"></i>
    </div>
    <div>
      <div>
        {fileName} <i className="text-muted small">({fileSize})</i>
      </div>
      <ul className="list-inline">
        <li className="list-inline-item mb-0">
          <a href="#" onClick={() => downloadFIle(download, fileName)}>
            Download
          </a>
        </li>
      </ul>
    </div>
  </MessageHover>
)
