import React, { PropsWithChildren, useEffect, useState,} from "react"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
    withRouter,
    RouteComponentProps, useLocation
} from "react-router-dom"
import {SignIn} from "App/components/Pages/SignIn"
import {ResetPassword} from "App/components/Pages/ResetPassword"
import {PhoneCode} from "App/components/Pages/PhoneCode"
import {Layout} from "App/components/Layout"
import {apiClient} from "App/components/services/api"
import {setUser, rootStore, setCurrentTenant, Store} from "./store"
import {useStore} from "effector-react"
import {AxiosError, AxiosResponse} from "axios";
import {useErrorHandler, withErrorBoundary} from "react-error-boundary";
import {ErrorFallback} from "./components/Error";
import { ChangePassword } from "./components/Pages/ChangePassword"
import {InstantLogin} from "./components/Pages/InstantLogin";

export const App = () => {
    const [isLoggedIn, setIsLoggedIn] = useState<null | boolean>(null)

    useEffect(() => {
        apiClient.get("/api/user").then((response: AxiosResponse<Store>) => {
            setUser(response.data);
            setIsLoggedIn(true);
            if (response.data.tenants.length === 1) {
                setCurrentTenant(response.data.tenants[0]);
            }

        }).catch(() => {
            setIsLoggedIn(true)
        })
    }, [isLoggedIn])

    if (isLoggedIn === null) {
        return null;
    }

    return (
        <Router>
            <Switch>

                <PrivateRoute exact path={"/change-password"}>
                    <ChangePassword />
                </PrivateRoute>

                <GuestRoute exact path="/login">
                    <SignIn />
                </GuestRoute>
                <GuestRoute exact path="/reset-password">
                    <ResetPassword />
                </GuestRoute>
                <Route exact path="/m/:id">
                    <PhoneRoute />
                </Route>
                <GuestRoute exact path={"/phone-code/:id"}>
                    <PhoneCode />
                </GuestRoute>

                <GuestRoute exact path="/instantlogin" >
                    <InstantLogin />
                </GuestRoute>

                <PrivateRoute exact path={["/", "/:id"]}>
                    <Layout/>
                </PrivateRoute>
            </Switch>
        </Router>
    )
}

type DirectLink = {
    needs_verification: boolean,
    group_id: boolean
}

const PhoneRoute = withErrorBoundary(withRouter((location: RouteComponentProps<{ id: string }>) => {
    const user = useStore(rootStore);
    const locationExternal = useLocation()
    const [response, setResponse] = useState<null | AxiosResponse<DirectLink>>(null);
    const handleError = useErrorHandler()

    useEffect(() => {
        apiClient.get(`/api/check-code/${location.match.params.id}`).then((response: AxiosResponse<DirectLink>) => {
            setResponse(response);
        }).catch((error: AxiosError) => handleError(error))

    }, []);

    if (response === null) {
        return null;
    }

    if (user.id === null) {
        return <Redirect to={{
            pathname: response.data.needs_verification ? `/phone-code/${location.match.params.id}` : "/login",
            state: {from: locationExternal}
        }} />
    }

    return <Redirect to={{
        pathname: `/${response.data.group_id}`,
        state: {from: locationExternal}
    }} />
}), {
    FallbackComponent: ErrorFallback
})

// @ts-ignore
function PrivateRoute({children, ...rest}: PropsWithChildren<any>) {
    const user = useStore(rootStore)

    return (
        <Route
            {...rest}
            render={({location}) =>
                user.id !== null ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: location}
                        }}
                        push={true}
                    />
                )
            }
        />
    );
}

// @ts-ignore
function GuestRoute({children, ...rest}) {
    let user = useStore(rootStore)

    return (
        <Route
            {...rest}
            render={({location}) =>
                user.id === null ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}

                        push={true}
                    />
                )
            }
        />
    );
}
