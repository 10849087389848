import { MessageHover } from "../MessageHover"
import React, {FC, MouseEvent, useState} from "react";
import styled from "@emotion/styled"
import linkifyHtml from "linkifyjs/html"
import parse from "html-react-parser"
import * as FeatherIcon from "react-feather";
import {sendRetrySMSRequest} from "../../api";


type Props = {
    date: Date,
    isOutgoingMessage: boolean
    text?: string,
    status?: string,
    id?: number
}

const SMSContainer = styled.div`
    position: relative;
`

export type statuses = "delivered" | "failed" | "processing" | "pending"

type indicatorProps  = {
    status: statuses,
    id: number
}

const FailedSMSIndicator = ({id}: {id: number}) => {
    const [isRetryingSMS, setRetryingSMS] = useState<boolean>(false)

    const retrySMS = (event: MouseEvent) => {
        event.preventDefault();

        (async () => {
            try {
                setRetryingSMS(true)

                await sendRetrySMSRequest(id)
                setRetryingSMS(false)
            } catch (e) {
                setRetryingSMS(false)
            }
        })()
    }

    if (isRetryingSMS) {
        return (
            <>
                <FeatherIcon.Smartphone color={mapToColor("failed")} size={"15px"} />
                <span>{status} <FeatherIcon.Loader size={"15px"} /></span>
            </>
        )
    }

    return (
        <>
            <FeatherIcon.Smartphone color={mapToColor("failed")} size={"15px"} />
            <span>{status} <button type="button" onClick={retrySMS}>Retry</button></span>
        </>
    )
}

const mapToColor = (status: statuses): string => {
    switch(status) {
        case "pending":
            return "grey";
        case "delivered":
            return "green";
        case "processing":
            return "blue"
        case "failed":
            return "red"
    }
}

const SMSIndicator = ({status, id}: indicatorProps) => {
    if (status === "failed") {
      return <FailedSMSIndicator id={id} />
    }

    return (
      <>
        <FeatherIcon.Smartphone color={mapToColor(status)} size={"15px"} />
        <span>{status}</span>
      </>
    );
}

export const SMSMessage: FC<Props> = ({date, isOutgoingMessage, text, status, id}) => {
    if (isOutgoingMessage) {
        return (
            <SMSContainer>
                <MessageHover date={date} isOutgoingMessage={isOutgoingMessage}>
                    {text && parse(
                        linkifyHtml(text, {
                            defaultProtocol: "https",
                            target: "_blank",
                        })
                    )}
                </MessageHover>

                {(status && id) ? (
                    <SMSIndicator status={status as statuses} id={id} />
                ) : ""}
            </SMSContainer>
        )
    }

    return (
        <SMSContainer>
            <MessageHover date={date} isOutgoingMessage={isOutgoingMessage}>
                {text && parse(
                    linkifyHtml(text, {
                        defaultProtocol: "https",
                        target: "_blank",
                    })
                )}
            </MessageHover>
            <FeatherIcon.Smartphone color={"grey"} size={"15px"} />
        </SMSContainer>
    )
};

