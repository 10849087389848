import * as AWS from "aws-sdk"
import { v4 as uuidv4 } from "uuid"
import axios from "axios"

export const sendMedia = (
  blob: Blob,
  doneRecording: (uuid: string, fileExtension?: string) => void,
  ext: string,
  folder: string|null,
) => {
  const uuid = uuidv4()

  axios
    .get(
      "https://tu9xgswggc.execute-api.eu-central-1.amazonaws.com/development/chat"
    )
    .then((response) => {
      // Keys returned from STS
      AWS.config.credentials = new AWS.Credentials(
        response.data.body.ACCESS_KEY,
        response.data.body.SECRET_KEY,
        response.data.body.SESSION_TOKEN
      )
      AWS.config.update({ region: "eu-central-1" })
      var params = {
        Body: blob,
        Bucket: "st8te",
        Key: folder ? `${folder}/${uuid}.${ext}` : `${uuid}.${ext}`,
        /*ServerSideEncryption: "AES256",*/
      }

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01",
      })

      s3.putObject(params, (err) => {
        if (err) {
          console.log(err, err.stack)
        }
      })
    })
    .catch((e) => {
      console.log(e)
    })

  // Send request to backend
  doneRecording(uuid, ext)
}
