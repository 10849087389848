import React, {FormEvent, useEffect, useState} from "react"
import { ReactComponent as Logo } from "assets/logo.svg"
import {useStore} from "effector-react";
import {rootStore, setCurrentTenant, setUser, Store} from "../../store";
import {apiClient} from "../services/api";
import {RouteComponentProps, useHistory, withRouter} from "react-router-dom";
import {AxiosError, AxiosResponse} from "axios";
import {useErrorHandler, withErrorBoundary} from "react-error-boundary";
import {ErrorFallback} from "../Error";


interface CodeResponse {
    user: Store,
    group_id: number
}

export const PhoneCode = withErrorBoundary(withRouter((location: RouteComponentProps<{ id: string }>) => {
  const user = useStore(rootStore)
  const [code, setCode] = useState("");
  const [response, setResponse] = useState<null | AxiosResponse<any>>(null);
  const handleError = useErrorHandler()
  const history = useHistory()

  useEffect(() => {
      document.body.classList.add("form-membership")

      return () => {
          document.body.classList.remove("form-membership")
      }
  }, [user.id])

  useEffect(() => {
      apiClient.post("/api/send-code/" + location.match.params.id).then((response) => response && setResponse(response));
  }, [location.match.params.id])

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (code.length === 0) {
          return
      }

      apiClient.post(`/api/verify-code/${location.match.params.id}`, {
          code
      }).catch((error: AxiosError) => {

      }).then((response: AxiosResponse<CodeResponse> | void) => {
          if (! response) {
              return
          }

          setUser(response.data.user)
          setCurrentTenant(response.data.user.tenants[0]);
          history.push("/change-password?redirect=" + response.data.group_id, history.location.state)
      });
  }

  if (response === null) {
      return null;
  }

  return (
    <div className="form-wrapper">
      <div className="logo">
        <Logo />
      </div>
      <h5>Enter code</h5>

      <form onSubmit={onSubmit}>
        <div className="form-group">
          <input
            type="text"
            className="form-control text-center"
            placeholder="_ _ _ _ _"
            autoFocus
            onChange={(e) => setCode(e.target.value)}
          />
        </div>
        <button className="btn btn-primary btn-block" type="submit">Confirm Code</button>
        <hr />
        <p className="text-muted">Take a different action.</p>
      </form>
    </div>
  )
}), {
    FallbackComponent: ErrorFallback,
    onError: (error: Error | AxiosError, info) => {
        const axiosError = error as AxiosError
        if (axiosError.isAxiosError) {

        }
    }
});
