import { createStore, createEffect } from "effector"
import { createEvent} from "effector-logger"
import { apiClient } from "App/components/services/api"
import { echo } from "App/components/services/webscokets"
import { getMessages } from "./api"
import {AxiosError, AxiosResponse} from "axios";
import { rootStore } from "App/store"


type Type =
  | ""
  | "outgoing-message"
  | "incoming-message"
  | "divider"
  | "outgoing-audio-message"
  | "outgoing-video-message"
  | "outgoing-file-message"
  | "outgoing-sms-message"

export type Message = {
  avatar: string
  date: Date
  media:
    | "text-message"
    | {
        type: "audio-message" | "video-message" | "file-message" | "sms-message"
        url: string
        download: string
        fileName?: string
        fileSize?: string
      }
  name: string
  text: string
  type: Type
  group_id: number,
  id: number,
  creator_id: number
}

type MessagesList = Message[]

type Cursor = {
  count: number,
  current: null | string,
  next: null | string,
  prev: null | string
}

export type Chat = {
  avatar: string
  date: Date
  id: number
  messages: MessagesList
  name: string
  text: string
  type: Type
  unread_messages: number,
  cursor: Cursor
}

type ChatsList = Chat[]

export type ChatStore = {
  chatsList: ChatsList
  selectedChat: Chat
}

const defaultStore: ChatStore = {
  chatsList: [],
  selectedChat: {
    avatar: "",
    date: new Date(),
    id: 0,
    messages: [],
    name: "",
    text: "",
    type: "",
    unread_messages: 0,
    cursor: {
      count: 0,
      current: null,
      next: null,
      prev: null
    }
  },
}

export const updateUnreadMessage = createEvent<{ groupId: number; isAdd?: boolean }>()
const setIncomingMessage = createEvent<Message>()

const user = () => rootStore.getState();

export const subscribeToChats = (chatsList: ChatsList) =>
  chatsList.forEach(({ id }: { id: number }) => {
    echo.private(`groups.${id}`).listen("NewMessage", (message: Message) => {
      updateUnreadMessage({ groupId: message.group_id, isAdd: true })
      setIncomingMessage(message)
    }).listen("PhoneStatusReceived", (message: Message) => {
        updateMessageSMSStatus(message);
    }).error(() => {

    })
  })

export const getNextPage = createEffect({
  handler: (id: number) => {
    const {selectedChat: {cursor}} = chatStore.getState() as ChatStore

    return new Promise<AxiosResponse|void>((resolve, reject) => {
      if (cursor.next === null) {
        return;
      }

      getMessages(cursor.next, id).then((response) => {
        resolve(response);
      }).catch((error: AxiosError) => {
        reject(error)
      })
    })
  },
})

export const setOutgoingMessage = createEvent<{ data: Message }>()
export const updateSelectedChat = createEvent<{ chat: Chat; messages: MessagesList, cursor: Cursor }>()
export const updateMessageSMSStatus = createEvent<Message>()

export const chatInit = createEffect({
  handler: async () => {
    const { data }: { data: { data: ChatsList } } = await apiClient.get("/api/groups")
    const chatsList = data.data

    subscribeToChats(chatsList)

    return chatsList.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
  },
})

const setMessageToChatList = (chatsList: ChatsList, { group_id, date, text, type }: Message) =>
  chatsList.reduce(
    (accum: ChatsList, chatPreview) =>
      chatPreview.id === group_id
        ? [
            {
              ...chatPreview,
              date,
              text,
              type,
            },
            ...accum,
          ]
        : [...accum, chatPreview],
    []
  )

export const chatStore = createStore(defaultStore)
  .on(getNextPage.done, (store, { result }) => {
    if (!result) {
      return store;
    }

    return {
      ...store,
      selectedChat: {
        ...store.selectedChat,
        cursor: result.data.meta.cursor,
        messages: [...result.data.data.reverse(), ...store.selectedChat.messages],
      }
    }
  })
  .on(chatInit.done, (store, { result }) => ({
    ...store,
    chatsList: result,
  }))
  .on(updateSelectedChat, (store, { chat, messages, cursor }) => ({
    ...store,
    selectedChat: {
      ...chat,
      messages: messages.reverse(),
      unread_messages: 0,
      cursor
    },
  }))
  .on(setOutgoingMessage, (store, { data }) => ({
    ...store,
    chatsList: setMessageToChatList(store.chatsList, data),
    selectedChat: {
      ...store.selectedChat,
      date: data.date,
      messages: [...store.selectedChat.messages, data],
    },
  }))
    .on(updateMessageSMSStatus, (store, message) => {
        if (store.selectedChat.id !== message.group_id) {
            return store;
        }

        if (store.selectedChat.messages.length === 0) {
            return store;
        }

        const messageCreator = message.creator_id;

        const messages: MessagesList = store.selectedChat.messages.map(existingMessage => {
            const userId = user().id;

            if (messageCreator !== null && userId !== null) {
                if (existingMessage.id === message.id && messageCreator === userId) {
                    return message
                }
            }

            return existingMessage;
        });

        return {
            ...store,
            selectedChat: {
                ...store.selectedChat,
                messages,
            }
        }
    })
  .on(setIncomingMessage, (store, newMessage) => ({
    ...store,
    chatsList: setMessageToChatList(store.chatsList, newMessage),
    selectedChat:
      store.selectedChat.id === newMessage.group_id
        ? {
            ...store.selectedChat,
            messages: [...store.selectedChat.messages, newMessage],
          }
        : store.selectedChat,
  }))
  .on(updateUnreadMessage, (store, { groupId, isAdd }) => {
    if (store.selectedChat.id !== groupId) {
      return {
        ...store,
        chatsList: store.chatsList.map((chat) =>
          chat.id === groupId
            ? {
                ...chat,
                unread_messages: isAdd ? chat.unread_messages + 1 : 0,
              }
            : chat
        ),
      }
    }
  })
