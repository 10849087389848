import { createStore, createEffect } from "effector"

type Store = {
  audio: null | boolean
  video: null | boolean
}

const defaultStore: Store = {
  audio: null,
  video: null,
}

export const checkMediaAvailable = createEffect({
  handler: (devices: { video: boolean; audio: boolean }) =>
    navigator.mediaDevices.getUserMedia(devices),
})

export const mediaStore = createStore(defaultStore)
  .on(checkMediaAvailable.done, (state, { params }) => ({
    ...state,
    audio: params.audio ? true : state.audio,
    video: params.video ? true : state.video,
  }))
  .on(checkMediaAvailable.fail, (state, { params }) => ({
    ...state,
    audio: params.audio ? false : state.audio,
    video: params.video ? false : state.video,
  }))
