import React, { useState } from "react"
import { MessageHover } from "App/Chat/MessagesView/MessageHover"
import { ImageView } from "./ImageView"
import { errorHandler } from "./handlers"
import { FileView } from "./FileView"
import {SMSMessage, statuses} from "./SMSMessage";

type MediaProps = {
  date: Date
  isOutgoingMessage: boolean
  url?: string
}

const supportedImageExtensions = [
    "png",
    "jpg",
    "jpeg",
    "gif",
    "webp"
]

const AudioView = ({ date, isOutgoingMessage, url }: MediaProps) => {
  const [key, updateKey] = useState(0)

  return (
    <MessageHover date={date} isOutgoingMessage={isOutgoingMessage} key={key}>
      <audio controls onError={() => errorHandler(key, updateKey)}>
        <source src={url} type="audio/mp3" />
      </audio>
    </MessageHover>
  )
}

const VideoView = ({ date, isOutgoingMessage, url }: MediaProps) => {
  const [key, updateKey] = useState(0)

  return (
    <MessageHover date={date} isOutgoingMessage={isOutgoingMessage} key={key}>
      <video width="320" height="240" controls muted onError={() => errorHandler(key, updateKey)}>
        <source src={url} type="audio/webm" />
        Your browser does not support the video tag.
      </video>
    </MessageHover>
  )
}

type Props = {
  type: "audio-message" | "video-message" | "file-message" | "sms-message"
  url: string
  download: string
  date: Date
  isOutgoingMessage: boolean
  fileName?: string
  fileSize?: string,
  text?: string
  fileExtension?: string,
  status?: statuses
  id?: number
}

export const MediaMessage = ({
  type,
  download,
  url,
  date,
  fileName,
  fileSize,
  status,
  isOutgoingMessage,
  text,
  fileExtension,
  id
}: Props) => {
  switch (type) {
    case "audio-message":
      return <AudioView date={date} isOutgoingMessage={isOutgoingMessage} url={url} />
    case "video-message":
      return <VideoView date={date} isOutgoingMessage={isOutgoingMessage} url={url} />
    case "sms-message": {
        return <SMSMessage date={date} isOutgoingMessage={isOutgoingMessage} text={text} status={status} id={id} />
    }
    default:
      let isImage = false;

      if (fileExtension) {
        isImage = supportedImageExtensions.includes(fileExtension)
      }

      return isImage ? (
        <ImageView
          download={download}
          fileName={fileName}
          url={url}
          isOutgoingMessage={isOutgoingMessage}
          date={date}
          fileSize={fileSize}
        />
      ) : (
        <FileView
          download={download}
          fileName={fileName}
          isOutgoingMessage={isOutgoingMessage}
          date={date}
          fileSize={fileSize}
        />
      )
  }
}
